import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import './style.scss';
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react'
import {BrowserRouter} from "react-router-dom";

const instance = createInstance({
    urlBase: 'https://www.circularorchids.com',
    siteId: 1,
    trackerUrl: "https://www.circularorchids.com/api/v9/session",
    srcUrl: "https://www.circularorchids.com/api/v9/session.js"
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// @ts-ignore
root.render(<React.StrictMode><MatomoProvider value={instance}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </MatomoProvider>
    </React.StrictMode>
);
