import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const PostOrder = () => {
    const [data, setData] = useState<any>();
    const {customerId} = useParams();
    const {trackEvent} = useMatomo();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`/api/promo-api/payment_status/${customerId}`);
            const body = await response.json();
            if (body.payment_status) {
                console.log(body);
                setData(body);
            } else {
                console.error(body);
            }

            if (body.payment_status !== 'pending') {
                trackEvent({
                    category: 'codes',
                    action: `order_${body.payment_status}`,
                });
                clearInterval(timer);
            }
        };

        const timer = setInterval(fetchData, 1000);

        return () => clearInterval(timer);
    }, [customerId]);


    const status = data?.payment_status;

    let message = (
        <i className="fas fa-4x fa-spin fa-refresh"/>
    );

    switch (status) {
        case 'verified':
            message = <>
                <h1>Thank you!</h1>
                <p>Your details have been confirmed. Enjoy your orchid!</p>
                <p>
                    You can always reach us through <a
                    href="mailto:contact@circularorchids.com?subject=Supoprt Request">contact@circularorchids.com</a>.
                </p>
                <a href="mailto:contact@circularorchids.com?subject=Supoprt Request" className="next">Contact
                    Support</a>
            </>
            break;
    }

    return (
        <div className="Page">
            <img className="logo" src="/logo.png"/>

            {message}
        </div>
    );
}