import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const PlantPage = () => {
    const {plantId} = useParams();
    const [plantData, setPlantData] = useState<any>();
    const {trackEvent} = useMatomo();

    const plantName = plantData?.name;
    useEffect(() => {
        if (plantName) {
            trackEvent({
                category: 'codes',
                action: 'scan',
                name: plantData.name
            })
        }
    }, [plantName]);

    useEffect(() => {
        const func = () => {
            fetch(`/api/promo-api/${plantId}`)
                .then(r => r.json())
                .then(setPlantData)
        };
        func();
        const timer = setInterval(func, 1000);

        return () => clearInterval(timer);
    }, []);

    const status = plantData?.status || 'open';

    let tagLine = 'Will you take me home today?';
    let body = <></>;

    switch (status) {
        case 'open':
            body = (
                <>
                    <h2>How it works</h2>
                    <ol>
                        <li>Provide your personal details</li>
                        <li>Verify through a bank payment</li>
                        <li>Take me home or have me delivered</li>
                        <li>We exchange the plants every three months, so you will always have beautiful,
                            flowering orchids.
                        </li>
                    </ol>

                    <h2>Pricing</h2>

                    <div className="pricing">
                        <div className="price">
                            <span>One Time</span>
                            <span className="amount">{plantData?.product?.deposit}</span>
                            <span>Deposit</span>
                        </div>
                        <div className="price">
                            <span>Monthly</span>
                            <span className="amount">{plantData?.product?.monthly}</span>
                            <span>Subscription</span>
                        </div>
                    </div>

                    <Link to={`/plants/${plantId}/user-details`} className="next">
                        Take me home now <i className="fa fa-arrow-right"/>
                    </Link>
                    <Link to={`/plants/${plantId}/user-details?delivery=true`} className="next">
                        Have my twin delivered <i className="fa fa-arrow-right"/>
                    </Link>
                    <a href="https://www.circularorchids.com" className="next">
                        More information <i className="fa fa-book"/>
                    </a>
                    <p>
                        Do you want to get notified when we have an update?
                    </p>
                    <Link to={`/`} className="next">
                        Keep in touch <i className="fa fa-envelope"/>
                    </Link>
                </>
            );
            break;
        case 'pending':
            body = <i className="fas fa-4x fa-spin fa-refresh"/>
            break;
        case 'canceled':
        case 'expired':
        case 'failed':
            body = <>something went wrong, please contact support: {status}</>;
            break;
        case 'paid':
            tagLine = 'Thank you for taking me home';
            break;
    }

    return (
        <div className="Page">
            <img className="logo" src="/logo.png"/>
            {!plantData ? (
                <></>
            ) : (
                <div>
                    <h1>Hi{plantData.owner ? ` ${plantData.owner}` : ''}, my name is <span
                        className="larger">{plantData?.name}</span></h1>
                    <p>{tagLine}</p>
                </div>
            )}

            {!plantData ? <></> : (
                !plantData.product ? (
                    <a className="next" href={`/api/admin/content/plants/${plantId}`}>Set product data</a>
                ) : (body)
            )}
        </div>
    );
}