import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const DetailsPage = () => {
    const {plantId} = useParams();
    const [plantData, setPlantData] = useState<any>();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const delivery = useLocation().search.includes('delivery');
    const {trackEvent} = useMatomo();

    useEffect(() => {
        setLoading(true);
        fetch(`/api/promo-api/${plantId}`)
            .then(r => r.json())
            .then(data => {
                setPlantData(data);
                setLoading(false);
            });
    }, []);

    return (
        <div className="Page">
            <img className="logo" src="/logo.png"/>
            {!plantData ? (
                <></>
            ) : (
                <div>
                    <h1>Hi, my name is <span className="larger">{plantData?.name}</span></h1>
                </div>
            )}

            <form onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                trackEvent({
                    category: 'codes',
                    action: 'place_order',
                    name: plantData.name
                });

                try {
                    const response = await fetch(`/api/promo-api/${plantId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name,
                            email,
                            phone_number: phoneNumber,
                            street_address: address,
                            city,
                            postal_code: postalCode,
                            delivery,
                        })
                    });

                    const body = await response.json();

                    if (response.status !== 200) {
                        setError(true);
                    }

                    window.location.href = body.checkout_url;

                } catch (e) {
                    console.error(e);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }}>
                <div className="field">
                    <label>Your Name</label>
                    <input required value={name} autoComplete="name" onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className="field">
                    <label>Email Address</label>
                    <input required value={email} type="email" autoComplete="email"
                           onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="field">
                    <label>Phone Number</label>
                    <input required value={phoneNumber} autoComplete="tel"
                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                </div>
                <div className="field">
                    <label>Address</label>
                    <input required value={address} autoComplete="street-address"
                           onChange={(e) => setAddress(e.target.value)}/>
                </div>
                <div className="fields">
                    <div className="field">
                        <label>Postal Code</label>
                        <input required value={postalCode} autoComplete="postal-code"
                               onChange={(e) => setPostalCode(e.target.value)}/>
                    </div>
                    <div className="field">
                        <label>City</label>
                        <input required value={city} autoComplete="city" onChange={(e) => setCity(e.target.value)}/>
                    </div>
                </div>
                <p>
                    <input id="tos" type="checkbox" required/><label htmlFor="tos"> I agree to the <a target="_blank"
                                                                                                      href="https://www.circularorchids.com/terms-and-conditions">Terms
                    and
                    Conditions</a>.</label>
                </p>

                {error && (
                    <div className="error">
                        Something went wrong while submitting your order, please try again.
                    </div>
                )}
                <button type="submit" className="next">{
                    loading ? <i className="fa fa-spin fa-refresh"/> : <>Verify bank account <i
                        className="fa fa-arrow-right"/></>
                }</button>
            </form>

        </div>
    );
}