import {useState} from "react";

export const HomePage = () => {
    const [state, setState] = useState<'none' | 'loading' | 'done'>('none');
    const [email, setEmail] = useState('');

    let icon = <></>;

    switch (state) {
        case "none":
            icon = <>Send</>
            break;
        case "loading":
            icon = <i className="fas fa-spin fa-refresh"/>
            break;
        case "done":
            icon = <i className="fas fa-check"/>
            break;

    }
    return (
        <div className="Page">
            <img className="logo" src="/logo.png"/>

            <h1>Being <span className="emp">sustainable</span> never looked so <span className="emp">beautiful</span>
            </h1>


            <h2>
                Amazing things are being built here.
            </h2>

            <p>
                Leave your email address below to hear when you can order your orchid.
            </p>

            <form onSubmit={async (e) => {
                e.preventDefault();
                setState('loading');
                try {
                    await fetch('/api/promo-api/email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({email}),
                    });
                } finally {
                    setState('done');
                }
            }}>
                <div className="fields">
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input disabled={state !== 'none'} required type="email" autoComplete="email" id="email"
                               style={{fontSize: '32px'}}
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="field">
                        <button disabled={state !== 'none'} type="submit" className="bordered next">
                            {icon}
                        </button>
                    </div>
                </div>
            </form>

            <a href="https://circularorchids.com" className="next">More Information</a>
            <a href="mailto:contact@circularorchids.com?subject=Supoprt Request" className="next">Contact Support</a>
        </div>
    );
}