import {Route, Routes, useLocation} from "react-router-dom";
import {PlantPage} from "./pages/PlantPage";
import {DetailsPage} from "./pages/DetailsPage";
import {HomePage} from "./pages/HomePage";
import {PostOrder} from "./pages/PostOrder";
import {useEffect} from "react";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const App = () => {
    const {pathname} = useLocation();
    const {trackPageView} = useMatomo();

    useEffect(() => {
        trackPageView({});
    }, [pathname]);
    return (
        <Routes>
            <Route path="/plants/:plantId" element={<PlantPage/>}/>
            <Route path="/plants/:plantId/user-details" element={<DetailsPage/>}/>
            <Route path="/post_verification/:customerId" element={<PostOrder/>}/>
            <Route index element={<HomePage/>}/>
        </Routes>
    );
}
